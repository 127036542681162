import React from "react";
import cityGift from "../Images/city_gift.png";
function GiftBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityGift} alt="gift.png" width="55" height="43" />
      </div>
      <div className="col-md-11" style={{ fontFamily: "Arial" }}>
        Once you receive and action your email from{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
          {process.env.REACT_APP_DESC_EMAIL}
        </a>
        ,{" "}
        <a href={"mailto:" + process.env.REACT_APP_DESC_PREP}>
          {process.env.REACT_APP_DESC_PREP}
        </a>{" "}
        or{" "}
        <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
          {process.env.REACT_APP_NO_REPLY}
        </a>
        , you can use the reward card virtually or request a card to be mailed
        to you.
      </div>
    </div>
  );
}
export default GiftBox;
